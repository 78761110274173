<template>
	<div>
		<a
			class="course-card"
			@click="goToCourse(course)"
		>
			<div
				class="course-card__pic"
				:style="courseBgrImage(course.image_course)"
			/>
			<div class="course-card__content">
				<h2 class="course-card__title">
					{{ course.name }}
				</h2>
				<div class="course-card__group">
					<div class="course-card__info">
						{{ course.duration | durationFormat }}
					</div>
				</div>
			</div>
			<div
				v-if="showButton"
				class="course-card__more"
			>
				Подробнее
				<img
					:src="'/img/icons/icon-angle-right-color.svg' | prodSrcImage"
					alt=""
				>
			</div>
		</a>
	</div>
</template>

<script>
export default {
    name: 'BaseCardCourseSlider',

    props: {
        course: {
            type: Object,
            default: null,
        },
        showButton: {
            type: Boolean,
            default: false,
        },
    },

    methods: {
        goToCourse(course) {
            window.location.href = `/courses/${course.slug}-${course.id}`
        },

        courseBgrImage(images) {
            let url = images.find(img => img.type === 'course_cover');

            if (url) return `background-image: url(${url.image});`
            else return 'background-image: url(/staticfiles/img/landscape-image.png);'
        },
    }
}
</script>

<style lang="less" scoped>
.course-card {
    position: relative;
    display: block;
    padding-bottom: 35px;
    color: #000;
    &__pic {
        overflow: hidden;
        position: relative;
        display: block;
        height: 225px;
        margin-bottom: 22px;
        background-position: center center;
        background-repeat: no-repeat;
        -webkit-background-size: cover;
        background-size: cover;
        border-radius: 4px;
        .transition;

        &:hover{
            opacity: 0.9;
        }
    }
    &__content {
        min-height: 92px;
        margin-bottom: 18px;
    }
    &__title {
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        height: 76px;
        margin-bottom: 20px;
        line-height: 1.2;
        font-size: 21px;
        font-weight: 400;
        .transition;
        &:hover {
            color: #EF722E;
        }
    }
    &__group {
        font-size: 0;
    }
    &__info {
        position: relative;
        display: inline-block;
        margin-right: 35px;
        font-size: 16px;
        color: #999;
        &:after {
            content: '';
            display: block;
            position: absolute;
            top: 9px;
            right: -19px;
            width: 4px;
            height: 4px;
            background: #B3B3B3;
            border-radius: 200px;
        }
        &:last-child {
            margin-right: 0;
            &:after {
                display: none;
            }
        }
    }
    &__more {
        display: inline-block;
        font-size: 14px;
        color: #EF722E;
        cursor: pointer;
        transition: all 0.2s ease-in-out;
    }
}
@media (max-width: 1099px) {
    course-card {
        max-width: 330px;
        margin: 0 auto;
        &__content {
            min-height: 0;
        }
    }
}
</style>
