<template>
	<ul class="socials">
		<li class="socials-item">
			<a
				href="https://www.instagram.com/lift2future/?igshid=1n01c5im6d6ct"
				target="_blank"
				class="socials-item__link"
			>
				<img
					v-if="white"
					:src="'/img/icons/icon-contact-instagram-white.svg' | prodSrcImage"
					alt=""
				>
				<img
					v-else
					:src="'/img/icons/icon-contact-instagram.svg' | prodSrcImage"
					alt=""
				>
			</a>
		</li>
		<li class="socials-item">
			<a
				href="https://vk.com/lift2future"
				target="_blank"
				class="socials-item__link"
			>
				<img
					v-if="white"
					:src="'/img/icons/icon-contact-vk-white.svg' | prodSrcImage"
					alt=""
				>
				<img
					v-else
					:src="'/img/icons/icon-contact-vk-2.png' | prodSrcImage"
					alt=""
				>
			</a>
		</li>
		<li class="socials-item">
			<a
				href="https://www.facebook.com/lift2future"
				target="_blank"
				class="socials-item__link"
			>
				<img
					v-if="white"
					:src="'/img/icons/icon-contact-facebook-white.svg' | prodSrcImage"
					alt=""
				>
				<img
					v-else
					:src="'/img/icons/icon-contact-facebook.svg' | prodSrcImage"
					alt=""
				>
			</a>
		</li>
	</ul>
</template>

<script>
export default {
    props: {
        white: {
            type: Boolean,
            default: false,
        }
    }
}
</script>

<style lang="less" scoped>
.socials {
    display:flex;
    &-item {
        display:flex;
        width:20px;
        height:20px;
        &:not(:last-child) {
            margin-right:20px;
        }
        &__link {
            display: flex;
            align-items: center;
            &:hover{
                opacity: 0.8;
            }
            img{
                width: 20px;
                height: auto;
            }
            &:hover{
                opacity: 0.8;
            }
        }
    }
}
</style>
