<template>
	<div class="field-wrap">
		<label class="field-label">
			<textarea
				v-model="localValue"
				class="field"
				:rows="countRows"
				:placeholder="placeholder"
			/>
			<label class="label">О себе:</label>
		</label>
	</div>
</template>

<script>
export default {
    props: {
        label: {
            type: String,
            default: 'Label'
        },
        placeholder: {
            type: String,
            default: 'Placeholder'
        },
        value: {
            type: String,
            required: false,
            default: ''
        },
        countRows: {
            type: Number,
            required: false,
            default: 4
        }
    },
    data() {
        return {
            localValue: ''
        };
    },
    watch: {
        value(newValue) {
            if (newValue !== this.localValue) {
                this.localValue = newValue;
            }
        },
        localValue(newValue, oldValue) {
            if (newValue !== oldValue) {
                this.$emit('input', newValue);
            }
        }
    },

    beforeMount() {
        this.localValue = this.value;
    }
};
</script>

<style lang="less" scoped>
textarea.field:not(:placeholder-shown) + .label {
    background: transparent;
}
</style>
